@import '@/styles/_global';
.root {
  label {
    // text-lg
    color: var(--P-Blue, #1a325d);
    font-variant-numeric: lining-nums proportional-nums;
    font-family: var(--font-raleway);
    font-size: toRem(16);
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: toRem(0.14);
    text-transform: capitalize;
  }

  input,
  select {
    color: var(--N-Grey-Dark, #686868);
    font-variant-numeric: lining-nums proportional-nums;
    font-family: var(--font-raleway);
    font-size: toRem(14);
    font-style: normal;
    font-weight: 500;
    line-height: toRem(18); /* 133.649% */
    letter-spacing: toRem(0.28);
  }

  input {
    padding: toRem(12);
  }
}

.applicationLoader{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 370px;
}

.inputWrapper {
  // mb-6 xl:mb-8 flex-col xl:flex-row
  margin-bottom: toRem(24);
  flex-direction: column;
}

.authorization {
  color: var(--N-Grey-Dark, #686868);
  font-family: var(--font-raleway);
  font-size: toRem(10);
  font-style: normal;
  font-weight: 500;
  letter-spacing: toRem(0.28);
  margin-bottom: toRem(16);
}

.emailContainer {
  flex-direction: column;
}

.error {
  color: var(--R-Red, #e60000);
  font-family: var(--font-raleway);
  font-size: toRem(12);
  font-style: normal;
  font-weight: 500;
  line-height: toRem(16); /* 133.649% */
  letter-spacing: toRem(0.24);
  margin-left: toRem(8);
}

.errorMessage{
  color: var(--R-Red, #e60000);
  font-size: toRem(14);
}

.formHeading {
  color: var(--primary-blue);
  font-family: var(--font-baskervville);
  font-size: toRem(24);
  font-style: normal;
  font-weight: 400;
  line-height: toRem(32); /* 133.75% */
  letter-spacing: toRem(-1);
  text-transform: capitalize;
  margin-bottom: toRem(28);
}
.commonMargins{
  margin-top: toRem(16);
  margin-bottom: toRem(16);
}

@include desktop() {
  .newLeadFormWrapper{
    display: flex;
    flex-wrap: wrap;
  }
  .newLeadFormFirstName{
    width: 50%;
    padding-right: toRem(20);
  }
  .newLeadFormPhone{
    width: 50%;
  }
  .newLeadFormEmail{
    width: 100%;
  }

  .formHeading {
    font-size: toRemDesktop(54);
    line-height: toRemDesktop(55); /* 101.852% */
    letter-spacing: toRemDesktop(-2);
    margin-bottom: toRemDesktop(54);
  }
  
  .root {
    
    label {
      font-size: toRemDesktop(20);
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: toRemDesktop(0.18);
    }

    input {
      padding: toRemDesktop(10) 0 toRemDesktop(10) toRemDesktop(20);
    }

    select {
      padding: toRemDesktop(10) toRemDesktop(40) toRemDesktop(12)
        toRemDesktop(20);
    }

    input,
    select,
    option {
      font-size: toRemDesktop(18);
      font-style: normal;
      font-weight: 500;
      line-height: toRemDesktop(32);
      letter-spacing: toRemDesktop(0.36);
    }
  }
  .inputWrapper {
    margin-bottom: toRemDesktop(32);
    flex-direction: row;
  }
  .authorization {
    font-size: toRemDesktop(16);
    font-style: normal;
    font-weight: 500;
    line-height: toRemDesktop(23); /* 143.75% */
    letter-spacing: toRemDesktop(0.32);
  }
  .commonMargins {
    margin-bottom: toRemDesktop(32);
  }
  .checkboxWrapper{
    margin-top: toRem(16);
    margin-bottom: toRem(16);
  }
  .emailContainer {
    flex-direction: row;
  }
  .error {
    font-size: toRemDesktop(12);
    line-height: toRemDesktop(16); /* 133.649% */
    margin-left: toRemDesktop(8);
  }

  .widthInputbox{
    width: 50%;
  }
  
  .widthInputboxFirstName{
    width: 50%;
  }
  
}

@include mobile{
  .newLeadFormWrapper{
    display: flex;
    flex-wrap: wrap;
  }
  .newLeadFormFirstName{
    width: 100%;
    margin-bottom: toRem(12);
  }
  .newLeadFormPhone{
    width: 100%;
    margin-bottom: toRem(12);
  }
  .newLeadFormEmail{
    width: 100%;
    margin-bottom: toRem(12);
    input{
      width: 100%;
    }
  }
  .newLeadFormEmailFullWidth {
    width: 100%;
    margin-bottom: toRem(12);
    input {
      width: 100%;
    }
  }
  .inputWrapper{
    margin-bottom: 0.8rem;
  }
  .checkboxWrapper{
    margin: 0;
  }

  .widthInputbox{
    width: 100%;
  }
  
  .widthInputboxFirstName{
    width: 100%;
  }

}
